export const COMPONENT = {
  INPUT: "INPUT",
  DROPDOWN: "DROPDOWN",
  IMAGE: "IMAGE",
  BUTTON: "BUTTON",
  NUMBER:"NUMBER"
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
