
export const NAV_MENU = [
  { url: "/home", label: "Home" },
  { url: "/about-us", label: "About Us" },
  { url: "/studyAbroad", label: "Study Abroad" },
  { url: "/online-training", label: "Online Training" },
  { url: "/web-development", label: "Web Developement", key: "webDevelopment" },
  { url: "/it-staffing", label: "IT Staffing" },
  { url: "/contact-us", label: "Contact Us" },
  { url: "/gallery", label: "Gallery" },
  { url: "/blogs", label: "Blogs" },

];
