import client1 from "./client1.png";
import client2 from "./client2.png";
import client3 from "./client3.jpeg"
import client4 from "./client4.jpeg"


export const clientLogos = [
  client1,
  client2,
  client4,
  client3,
]